import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SUPPORT_EMAIL } from '../../../constants/supportLinks';

import './SupportLabel.less';

export const SupportLabel: FC = () => {
  const { t } = useTranslation('profile', { useSuspense: false });
  return (
    <div className="support-label">
      <span>{t('contactSupport')}</span>
      <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
    </div>
  );
};
