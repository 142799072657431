import { RootStateType } from '../store';

export const authSelector = (state: RootStateType) => {
  return state.authReducer.isAuth;
};

export const isAuthLoadingSelector = (state: RootStateType) => {
  return state.authReducer.isLoading;
};

export const isFirstLoginSelector = (state: RootStateType) => {
  return state.authReducer.isFirstLogin;
};

export const isFirstLoginDataSelector = (state: RootStateType) => {
  return state.authReducer.isFirstLoginData;
};

export const loginErrorCodeSelector = (state: RootStateType) => {
  return state.authReducer.loginErrorCode;
};

export const loginErrorSelector = (state: RootStateType) => {
  return state.authReducer.loginError;
};

export const isShowFormSelector = (state: RootStateType) => {
  return state.authReducer.isShowForm;
};
