import { router } from '../../components/layouts/AppRouter/AppRouter';
import { RoutePath } from '../../constants/RoutePath';
import { TEMPORARY_PASSWORD_ERROR, USER_NOT_FOUND, USER_EMAIL_IS_UNVERIFIED } from '../../constants/errorMessages';
import { REDIRECT_URL } from '../../constants/queries';
import { HTTPCodes } from '../../enum/HTTPCodes';
import { Token } from '../../enum/Token';
import { UserRole } from '../../enum/UserRole';
import { AppNotificationType } from '../../interfaces/IAppNotification';
import { resetPasswordApi } from '../../services/api/authApi';
import {
  deleteLoggedUserApi,
  disable2faApi,
  enable2fApi,
  getLoggedUserApi,
  getLoggedUserWithTokenApi,
  updateUserDataApi,
  verify2faCodeApi,
} from '../../services/api/userApi';
import { toDataURL } from '../../util/getBase64Image';
import { getUserFromToken } from '../../util/getUserFromToken';
import { getRedirectUrlPath } from '../../util/redirectToUrl';
import { removeVisitedPage } from '../../util/saveVisitedPage';
import { translate, tryToTranslate } from '../../util/translate';
import { setNotification } from '../app/appReducer';
import { handleApiError } from '../app/appThunk';
import {
  setAuth, setIsFirstLogin, setIsFirstLoginData, setLoginError, setLoginErrorCode,
} from '../auth/authReducer';
import { logoutAccount } from '../auth/authThunk';
import { ThunkActionType } from '../store';
import {
  setAvatar,
  setIsFetchingUserData,
  setIsLoading2faSetup,
  setIsShowResetPasswordModal,
  setIsShowSetup2FAModal,
  setIsSuperAdmin,
  setSetup2faData,
  setTokens,
  setUser,
  setUserId,
} from '../user/userReducer';

export const initUserData = (
  redirectCallback?: (isFirstLogin?: boolean) => void,
  accessToken?: string,
  refreshToken?: string,
): ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsFetchingUserData(true));

      let res;

      if (redirectCallback && accessToken) {
        res = await getLoggedUserWithTokenApi(accessToken);
      } else {
        res = await getLoggedUserApi();
      }

      dispatch(setUser(res));
      dispatch(setIsSuperAdmin(res.role === UserRole.SUPER_USER));

      if (res.avatar) {
        toDataURL(res.avatar, (avatar: string | null) => dispatch(setAvatar(avatar)));
      }

      dispatch(setIsFetchingUserData(false));

      if (res.active2fa && redirectCallback) {
        dispatch(setTokens({
          accessToken,
          refreshToken,
        }));
        const { search } = window.location;

        router.navigate(`${RoutePath.VERIFY_CODE}${search.includes(REDIRECT_URL) ? search : ''}`);
      } else {
        dispatch(setAuth(true));
        if (accessToken && refreshToken) {
          localStorage.setItem(Token.ACCESS_TOKEN, accessToken);
          localStorage.setItem(Token.REFRESH_TOKEN, refreshToken);
        }
        redirectCallback?.();
      }
    } catch (err: any) {
      const errorCode = err?.response?.status;
      const errorMessage = err?.response?.data?.message;

      if (
        errorCode === HTTPCodes.Forbidden
        && errorMessage && errorMessage === TEMPORARY_PASSWORD_ERROR
      ) {
        const token = localStorage.getItem(Token.ACCESS_TOKEN);

        if (token) {
          dispatch(setIsFirstLogin(true));
          dispatch(setIsFirstLoginData(true));
          dispatch(setAuth(true));
          redirectCallback?.(true);
        }
      } else if (
        errorCode === HTTPCodes.Forbidden
        && errorMessage && errorMessage === USER_EMAIL_IS_UNVERIFIED
      ) {
        dispatch(setTokens({
          accessToken,
          refreshToken,
        }));
        router.navigate(getRedirectUrlPath(RoutePath.NOT_VERIFIED));
      } else if (
        errorCode === HTTPCodes.NotFound
        && errorMessage && errorMessage.includes(USER_NOT_FOUND)
      ) {
        dispatch(logoutAccount());
        dispatch(setLoginError(translate(`apiErrors.${USER_NOT_FOUND}`)));
        dispatch(setLoginErrorCode(errorCode));
      } else if (err?.response?.data?.error_description) {
        const errorDescription = tryToTranslate(err?.response?.data?.error_description);
        dispatch(setLoginErrorCode(errorCode));
        dispatch(setLoginError(errorDescription));
      } else {
        const errorDescription = tryToTranslate(err?.message);
        dispatch(setLoginErrorCode(errorCode));
        dispatch(setLoginError(errorDescription));
        dispatch(logoutAccount());
      }
    }
  };
};

export const updateUserData = (
  data: FormData,
  reset: boolean,
  redirectCallback?: () => void,
): ThunkActionType => {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsFetchingUserData(true));

      const { avatar, user } = getState().userReducer;

      const res = await updateUserDataApi(data, reset);
      dispatch(setUser({
        ...user,
        ...res,
      }));
      if (res.avatar) {
        toDataURL(res.avatar, (avatar: string | null) => dispatch(setAvatar(avatar)));
      } else if (avatar) {
        dispatch(setAvatar(null));
      }

      redirectCallback?.();
    } catch (err: any) {
      dispatch(handleApiError(err));
    } finally {
      const { isFirstLoginData } = getState().authReducer;
      if (isFirstLoginData) {
        dispatch(setIsFirstLoginData(false));
      }
      dispatch(setIsFetchingUserData(false));
    }
  };
};

export const resetPassword = (password: string): ThunkActionType => {
  return async (dispatch) => {
    try {
      await resetPasswordApi(password);
      dispatch(setIsShowResetPasswordModal(false));
    } catch (err) {
      dispatch(handleApiError(err));
    }
  };
};

export const getUserId = (): ThunkActionType => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(Token.ACCESS_TOKEN);

      if (token) {
        const data = getUserFromToken(token);
        dispatch(setUserId(data.id || null));
      }
    } catch (err) {
      dispatch(handleApiError(err));
    }
  };
};

export const getSetup2faData = (): ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading2faSetup(true));

      const res = await enable2fApi();

      dispatch(setSetup2faData({
        ...res,
      }));
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setIsLoading2faSetup(false));
    }
  };
};

export const enable2FA = (code: string, callback?: () => void): ThunkActionType => {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading2faSetup(true));

      const { tokens } = getState().userReducer;

      await verify2faCodeApi(code, tokens?.accessToken ?? '');
      dispatch(initUserData());
      dispatch(setIsShowSetup2FAModal(false));
      callback?.();
    } catch (err) {
      dispatch(handleApiError(err));
      dispatch(setLoginError(translate('errors.codeError')));
      dispatch(setLoginErrorCode(HTTPCodes.BadRequest));
    } finally {
      dispatch(setIsLoading2faSetup(false));
    }
  };
};

export const disable2FA = (): ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading2faSetup(true));

      await disable2faApi();
      dispatch(initUserData());
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setIsLoading2faSetup(false));
    }
  };
};

export const verify2faCode = (
  code: string,
  redirectCallback: () => void,
): ThunkActionType => {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading2faSetup(true));

      const { tokens } = getState().userReducer;

      await verify2faCodeApi(code, tokens?.accessToken ?? '');
      if (tokens?.accessToken) {
        localStorage.setItem(Token.ACCESS_TOKEN, tokens.accessToken);
      }
      if (tokens?.refreshToken) {
        localStorage.setItem(Token.REFRESH_TOKEN, tokens.refreshToken);
      }
      redirectCallback();
      dispatch(setAuth(true));
    } catch (err) {
      dispatch(setLoginError(translate('errors.codeError')));
      dispatch(setLoginErrorCode(HTTPCodes.BadRequest));
    } finally {
      dispatch(setIsLoading2faSetup(false));
    }
  };
};

export const deleteLoggedUser = (): ThunkActionType => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().userReducer;

      if (user) {
        await deleteLoggedUserApi();
        removeVisitedPage(user.id);
        dispatch(logoutAccount());
        dispatch(setNotification({
          type: AppNotificationType.MESSAGE,
          level: 'success',
          message: translate('profileRemoved'),
        }));
      }
    } catch (err) {
      dispatch(handleApiError(err));
    }
  };
};
