import { ComponentType } from 'react';

const REFRESH_KEY = 'retry-lazy-refreshed';

const lazyRetry = (componentImport: () => Promise<{ default: ComponentType<any>; }>)
: Promise<{ default: ComponentType<any>; }> => {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(REFRESH_KEY) || 'false',
    );
    // try to import the component
    componentImport().then((component: { default: ComponentType<any>; }) => {
      window.sessionStorage.setItem(REFRESH_KEY, 'false');
      resolve(component);
    // eslint-disable-next-line consistent-return
    }).catch((error: any) => {
      if (!hasRefreshed) {
        window.sessionStorage.setItem(REFRESH_KEY, 'true');
        return window.location.reload();
      }
      reject(error);
    });
  });
};

export default lazyRetry;
