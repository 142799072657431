import { Provider } from 'react-redux';
import { FC, ReactNode } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import useMuiTheme from '../../../hooks/useMuiTheme';

interface IComponentProps {
 store: any;
 children: ReactNode;
}

const ProviderWrapper: FC<IComponentProps> = ({
  store,
  children,
}) => {
  const theme = useMuiTheme();

  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
};

export default ProviderWrapper;
