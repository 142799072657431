import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatchType = typeof store.dispatch;
export type RootStateType = ReturnType<typeof store.getState>;
export type ThunkActionType = ThunkAction<void, RootStateType, unknown, AnyAction>;
