import { RoutePath } from '../../constants/RoutePath';
import { Token } from '../../enum/Token';
import { IAuthData, ISignUpData } from '../../interfaces/IAuthData';
import {
  getUserInvitationApi,
  loginApi,
  logoutApi,
  refreshTokensApi,
  requestPasswordNoAuthApi,
  requestPasswordResetApi,
  resendVerificationEmailApi,
  resetPasswordApi,
  responseToInvitationApi,
  signUpApi,
  validateTokenApi,
  verifyEmailApi,
} from '../../services/api/authApi';
import { getRolesFromToken } from '../../util/getRolesFromToken';
import { handleApiError } from '../app/appThunk';
import { ThunkActionType } from '../store';
import {
  setAvatar, setIsSuperAdmin, setRoles, setUser,
} from '../user/userReducer';
import { initUserData } from '../user/userThunks';
import {
  setAuth,
  setIsAuthLoading,
  setIsFirstLogin,
  setIsFirstLoginData,
  setIsShowForm,
  setLoginError,
  setLoginErrorCode,
} from './authReducer';
import { redirectToUrl } from '../../util/redirectToUrl';
import { arrayBufferToString } from '../../util/arrayBufferToString';
import { setApiError, setIsAppLoader, setNotification } from '../app/appReducer';
import { REDIRECT_URL } from '../../constants/queries';
import { router } from '../../components/layouts/AppRouter/AppRouter';
import { resetWorkspaceDetail } from '../workspaceDetail/workspaceDetailReducer';
import { resetWorkspaces } from '../workspaces/workspacesReducer';
import { HTTPCodes } from '../../enum/HTTPCodes';
import { InvitationResponse } from '../../enum/InvitationResponse';
import { IAccountInvitation } from '../../interfaces/IAccount';
import { AppNotificationType } from '../../interfaces/IAppNotification';
import { convertAnyWorkspaceNameApi, convertWorkspaceNameApi } from '../../services/api/accountApi';
import { translate, tryToTranslate } from '../../util/translate';
import { ACCOUNT_DISABLED } from '../../constants/errorMessages';

const redirectToLogin = () => {
  const { search } = window.location;

  router.navigate(`${RoutePath.LOGIN}${search.includes(REDIRECT_URL) ? search : ''}`, {
    replace: true,
  });
};

export const resetAppData = (): ThunkActionType => {
  return (dispatch) => {
    dispatch(setAuth(false));
    dispatch(setIsShowForm(true));
    dispatch(setUser(null));
    dispatch(setAvatar(null));
    dispatch(setRoles(null));
    dispatch(setIsFirstLogin(false));
    dispatch(setIsFirstLoginData(false));
    dispatch(setIsSuperAdmin(false));
    dispatch(resetWorkspaceDetail());
    dispatch(resetWorkspaces());
    dispatch(setIsAppLoader(false));
  };
};

export const loginAccount = (
  data: IAuthData,
  redirectToMainPage?: (isFirstLogin?: boolean) => void,
): ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsAuthLoading(true));

      const res = await loginApi(data);

      const roles = getRolesFromToken(res.access_token);
      await dispatch(setRoles(roles));
      await dispatch(initUserData(redirectToMainPage, res.access_token, res.refresh_token));
    } catch (err: any) {
      const errorCode = err?.response?.status;
      let errorDescription = tryToTranslate(err?.response?.data?.error_description);

      if (errorCode === HTTPCodes.BadRequest && errorDescription === ACCOUNT_DISABLED) {
        errorDescription = translate('invalidEmailPassword', 'login');
      }

      dispatch(setLoginErrorCode(errorCode));
      dispatch(setLoginError(errorDescription));
    } finally {
      dispatch(setIsAuthLoading(false));
    }
  };
};

export const signUp = (
  data: ISignUpData,
  redirectToLogin: () => void,
): ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsAuthLoading(true));

      await signUpApi(data);
      redirectToLogin();
    } catch (err: any) {
      const errorCode = err?.response?.status;

      const errorDescription = tryToTranslate(err?.response?.data?.message);

      dispatch(setLoginErrorCode(errorCode));
      dispatch(setLoginError(errorDescription));
    } finally {
      dispatch(setIsAuthLoading(false));
    }
  };
};

export const logoutAccount = (): ThunkActionType => {
  return async (dispatch, getState) => {
    try {
      const { isAuth } = getState().authReducer;

      const access_token = localStorage.getItem(Token.ACCESS_TOKEN);

      if (isAuth && access_token) {
        await logoutApi();
      }
    } catch (error: any) {
      if (error?.response && error.response?.data) {
        const data = error.response.data instanceof ArrayBuffer
          ? JSON.parse(arrayBufferToString(error.response.data)) : error.response.data;
        dispatch(setApiError({
          error: tryToTranslate(data.error || data.message),
          errorTarget: data.errorTarget,
        }));
      }
    } finally {
      localStorage.removeItem(Token.ACCESS_TOKEN);
      localStorage.removeItem(Token.REFRESH_TOKEN);

      dispatch(resetAppData());

      redirectToLogin();
    }
  };
};

export const updateTokens = (): ThunkActionType => {
  return async (dispatch, getState) => {
    try {
      const refreshToken = localStorage.getItem(Token.REFRESH_TOKEN);
      if (refreshToken) {
        const token = await refreshTokensApi(refreshToken);

        localStorage.setItem(Token.ACCESS_TOKEN, token.data.access_token);
        localStorage.setItem(Token.REFRESH_TOKEN, token.data.refresh_token);

        dispatch(setRoles(getRolesFromToken(token.data.access_token)));
      }
    } catch (err) {
      const { isAuth } = getState().authReducer;

      if (isAuth) {
        dispatch(logoutAccount());
      }
    }
  };
};

export const resetPassword = (password: string, routeToUserPage: () => void): ThunkActionType => {
  return async (dispatch) => {
    try {
      await resetPasswordApi(password);
      dispatch(setIsFirstLogin(false));
      dispatch(setAuth(true));
      routeToUserPage();
    } catch (err) {
      dispatch(handleApiError(err));
    }
  };
};

export const resetPasswordNoAuth = (
  token: string,
  password: string,
  redirectToLogin: () => void,
): ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsAuthLoading(true));

      await requestPasswordNoAuthApi(token, password);
      redirectToLogin();
    } catch (err: any) {
      const errorCode = err?.response?.status;
      const errorDescription = tryToTranslate(err?.response?.data?.message);

      dispatch(setLoginErrorCode(errorCode));
      dispatch(setLoginError(errorDescription));
    } finally {
      dispatch(setIsAuthLoading(false));
    }
  };
};

export const validateToken = (
  redirectUrl: string | null,
): ThunkActionType => {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsShowForm(false));

      const access_token = localStorage.getItem(Token.ACCESS_TOKEN);
      const refresh_token = localStorage.getItem(Token.REFRESH_TOKEN);

      if (access_token && refresh_token) {
        const { loggedAccount, user } = getState().userReducer;
        if (loggedAccount?.id) {
          await validateTokenApi(loggedAccount.id);
        }

        if (redirectUrl) {
          await redirectToUrl(redirectUrl);
        } else {
          if (!user) {
            dispatch(initUserData());
          }
          dispatch(setAuth(true));
        }
      } else {
        throw Error();
      }
    } catch (err: any) {
      dispatch(setIsShowForm(true));
    }
  };
};

export const verifyEmail = (
  token: string | null,
  redirectCallback: () => void,
): ThunkActionType => {
  return async (dispatch) => {
    try {
      await verifyEmailApi(token);
      redirectCallback();
    } catch (err: any) {
      const errorCode = err?.response?.status;
      let errorDescription = translate('somethingWentWrong');

      if (errorCode === HTTPCodes.Conflict) {
        errorDescription = tryToTranslate(err?.response?.data?.message);
      }
      dispatch(setApiError({
        error: errorDescription,
        errorCode,
      }));
    }
  };
};

export const resendVerificationEmail = (
  redirectUrl: string,
  redirectCallback: () => void,
): ThunkActionType => {
  return async (dispatch, getState) => {
    try {
      const { tokens } = getState().userReducer;

      await resendVerificationEmailApi(redirectUrl, tokens?.accessToken ?? '');
      dispatch(setNotification({
        type: AppNotificationType.MESSAGE,
        level: 'success',
        message: translate('invitationSend'),
      }));
      redirectCallback();
    } catch (err: any) {
      const errorCode = err?.response?.status;
      let errorDescription = translate('somethingWentWrong');

      if (errorCode === HTTPCodes.Conflict) {
        errorDescription = tryToTranslate(err?.response?.data?.message);
      }
      dispatch(setApiError({
        error: errorDescription,
        errorCode,
      }));
    }
  };
};

export const responseToInvitation = (
  account: string,
  response: InvitationResponse,
  redirectCallback: (isAccepted: boolean) => void,
): ThunkActionType => {
  return async (dispatch, getState) => {
    try {
      const { isSuperAdmin } = getState().userReducer;
      let accountId: number;

      if (isSuperAdmin) {
        accountId = (await convertAnyWorkspaceNameApi(account))?.accountId;
      } else {
        accountId = (await convertWorkspaceNameApi(account))?.accountId;
      }
      await responseToInvitationApi(accountId, response);
      redirectCallback(response === InvitationResponse.APPROVED);
    } catch (err: any) {
      const errorCode = err?.response?.status;
      let errorDescription = translate('somethingWentWrong');

      if (errorCode === HTTPCodes.Conflict) {
        errorDescription = tryToTranslate(err?.response?.data?.message);
      }
      dispatch(setApiError({
        error: errorDescription,
        errorCode,
      }));
    }
  };
};

export const getUserInvitation = (
  account: string,
  redirectCallback: (data: IAccountInvitation) => void,
): ThunkActionType => {
  return async (dispatch, getState) => {
    try {
      const { isSuperAdmin } = getState().userReducer;
      let accountId: number;

      if (isSuperAdmin) {
        accountId = (await convertAnyWorkspaceNameApi(account))?.accountId;
      } else {
        accountId = (await convertWorkspaceNameApi(account))?.accountId;
      }
      const res = await getUserInvitationApi(accountId);
      redirectCallback(res);
    } catch (err: any) {
      const errorCode = err?.response?.status;
      let errorDescription = translate('somethingWentWrong');

      if (errorCode === HTTPCodes.Conflict) {
        errorDescription = tryToTranslate(err?.response?.data?.message);
      }
      dispatch(setApiError({
        error: errorDescription,
        errorCode,
      }));
    }
  };
};

export const requestPasswordReset = (
  email: string,
  resirectUrl: string,
  redirectCallback: () => void,
): ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setLoginError(null));
      dispatch(setLoginErrorCode(null));
      await requestPasswordResetApi(email, resirectUrl);
      redirectCallback();
    } catch (err: any) {
      const errorCode = err?.response?.status;
      const errorDescription = tryToTranslate(err?.response?.data?.message);

      dispatch(setLoginErrorCode(errorCode));
      let error = errorDescription;
      if (errorDescription?.includes('already exists')) {
        error = translate('resetLinkAlreadySent', 'resetPassword');
      }
      dispatch(setLoginError(error));
    }
  };
};
