import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAccount } from '../../interfaces/IAccount';

export interface IAccountsState {
  workspaces: IAccount[];
  workspacesHeader: IAccount[];
  isFetchingWorkspaces: boolean;
  triggerWorkspaces: {};
  triggerWorkspacesHeader: {};
  totalCount: number;
  totalCountHeader: number;
}

export const initialState: IAccountsState = {
  workspaces: [],
  workspacesHeader: [],
  isFetchingWorkspaces: false,
  triggerWorkspaces: {},
  triggerWorkspacesHeader: {},
  totalCount: 0,
  totalCountHeader: 0,
};

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    setWorkspaces(state, action: PayloadAction<IAccount[]>) {
      state.workspaces = action.payload;
    },
    setWorkspacesHeader(state, action: PayloadAction<IAccount[]>) {
      state.workspacesHeader = action.payload;
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload;
    },
    setTotalCountHeader(state, action: PayloadAction<number>) {
      state.totalCountHeader = action.payload;
    },
    setIsFetchingWorkspaces(state, action: PayloadAction<boolean>) {
      state.isFetchingWorkspaces = action.payload;
    },
    setTriggerWorkspaces(state) {
      state.triggerWorkspaces = {};
    },
    setTriggerWorkspacesHeader(state) {
      state.triggerWorkspacesHeader = {};
      state.workspacesHeader = [];
      state.totalCountHeader = 0;
    },
    resetWorkspaces: () => initialState,
  },
});

export const {
  setWorkspaces,
  setTotalCount,
  setIsFetchingWorkspaces,
  setTriggerWorkspaces,
  resetWorkspaces,
  setTotalCountHeader,
  setWorkspacesHeader,
  setTriggerWorkspacesHeader,
} = workspacesSlice.actions;

export default workspacesSlice.reducer;
