import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IApiKey } from '../../interfaces/IApiKey';

export interface IApiKeysState {
  keys: IApiKey[];
  isFetchingKeys: boolean;
  isShowAddApiKeyModal: boolean;
  triggerApiKeys: {};
  apiKeyModalData: IApiKey | null;
}

export const initialState: IApiKeysState = {
  keys: [],
  isFetchingKeys: false,
  isShowAddApiKeyModal: false,
  triggerApiKeys: {},
  apiKeyModalData: null,
};

export const apiKeys = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    setApiKeys(state, action: PayloadAction<IApiKey[]>) {
      state.keys = action.payload;
    },
    setIsFetchingApiKeys(state, action: PayloadAction<boolean>) {
      state.isFetchingKeys = action.payload;
    },
    setIsShowAddApiKeyModal(state, action: PayloadAction<boolean>) {
      state.isShowAddApiKeyModal = action.payload;
    },
    setApiKeyModalData(state, action: PayloadAction<IApiKey | null>) {
      state.apiKeyModalData = action.payload;
    },
    setTriggerApiKeys(state) {
      state.triggerApiKeys = {};
    },
    resetApiKeysData: () => initialState,
  },
});

export const {
  setApiKeys,
  setIsFetchingApiKeys,
  setIsShowAddApiKeyModal,
  setTriggerApiKeys,
  resetApiKeysData,
  setApiKeyModalData,
} = apiKeys.actions;

export default apiKeys.reducer;
