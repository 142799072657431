import { AxiosInstance } from 'axios';

import { HTTPCodes } from '../../../enum/HTTPCodes';
import { Token } from '../../../enum/Token';
import { store } from '../../../store/store';
import { refreshTokensApi } from '../authApi';
import { logoutAccount } from '../../../store/auth/authThunk';
import { VERIFICATION_2FA_FAILED } from '../../../constants/errorMessages';

export function responseInterceptors(instanceAxios: AxiosInstance) {
  return instanceAxios.interceptors.response.use((config) => {
    return config;
  }, async (error) => {
    const originalRequest = error.config;

    if (error.response.status === HTTPCodes.Unauthorized
      && originalRequest && !originalRequest.isRetry) {
      originalRequest.isRetry = true;

      const refreshToken = localStorage.getItem(Token.REFRESH_TOKEN);

      if (refreshToken) {
        try {
          const res = await refreshTokensApi(refreshToken);

          localStorage.setItem(Token.ACCESS_TOKEN, res.data.access_token);
          localStorage.setItem(Token.REFRESH_TOKEN, res.data.refresh_token);

          return instanceAxios(originalRequest);
        } catch (err: any) {
          localStorage.removeItem(Token.ACCESS_TOKEN);
          localStorage.removeItem(Token.REFRESH_TOKEN);
          store.dispatch(logoutAccount());
        }
      } else if (error.response.data?.message !== VERIFICATION_2FA_FAILED) {
        store.dispatch(logoutAccount());
      }
    }
    throw error;
  });
}
