import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IService } from '../../interfaces/IService';
import { IUser } from '../../interfaces/IUser';
import { IServiceType } from '../../interfaces/IServiceType';
import { IGroup } from '../../interfaces/IGroup';

export interface IServiceDetailsState {
  data: IService | null;
  isFetchingService: boolean;
  isLoadingError: boolean;
  users: IUser[];
  usersTotalCount: number;
  triggerUsers: {};
  isFetchingUsers: boolean;
  groups: IGroup[];
  groupsTotalCount: number;
  triggerGroups: {};
  isFetchingGroups: boolean;
  serviceType: IServiceType | null;
  isShowChangeUserRoleModal: boolean;
  isShowAddUserModal: boolean;
  isShowChangeGroupRoleModal: boolean;
  isShowAddGroupModal: boolean;
  isAdmin: boolean;
}

export const initialState: IServiceDetailsState = {
  data: null,
  isFetchingService: false,
  isLoadingError: false,
  users: [],
  usersTotalCount: 0,
  triggerUsers: {},
  isFetchingUsers: false,
  groups: [],
  groupsTotalCount: 0,
  triggerGroups: {},
  isFetchingGroups: false,
  serviceType: null,
  isShowChangeUserRoleModal: false,
  isShowAddUserModal: false,
  isShowChangeGroupRoleModal: false,
  isShowAddGroupModal: false,
  isAdmin: false,
};

export const serviceDetail = createSlice({
  name: 'serviceDetail',
  initialState,
  reducers: {
    setService(state, action: PayloadAction<IService | null>) {
      state.data = action.payload;
    },
    setIsFetchingService(state, action: PayloadAction<boolean>) {
      state.isFetchingService = action.payload;
    },
    setIsLoadingError(state, action: PayloadAction<boolean>) {
      state.isLoadingError = action.payload;
    },
    setIsShowChangeUserRoleModal(state, action: PayloadAction<boolean>) {
      state.isShowChangeUserRoleModal = action.payload;
    },
    setIsShowAddUserModal(state, action: PayloadAction<boolean>) {
      state.isShowAddUserModal = action.payload;
    },
    setIsShowChangeGroupRoleModal(state, action: PayloadAction<boolean>) {
      state.isShowChangeGroupRoleModal = action.payload;
    },
    setIsShowAddGroupModal(state, action: PayloadAction<boolean>) {
      state.isShowAddGroupModal = action.payload;
    },
    setServiceUsers(state, action: PayloadAction<IUser[]>) {
      state.users = action.payload;
    },
    setUsersTotalCount(state, action: PayloadAction<number>) {
      state.usersTotalCount = action.payload;
    },
    setTriggerUsers(state) {
      state.triggerUsers = {};
    },
    setIsFetchingUsers(state, action: PayloadAction<boolean>) {
      state.isFetchingUsers = action.payload;
    },
    setServiceType(state, action: PayloadAction<IServiceType | null>) {
      state.serviceType = action.payload;
    },
    setIsServiceAdmin(state, action: PayloadAction<boolean>) {
      state.isAdmin = action.payload;
    },
    setServiceGroups(state, action: PayloadAction<IGroup[]>) {
      state.groups = action.payload;
    },
    setGroupsTotalCount(state, action: PayloadAction<number>) {
      state.groupsTotalCount = action.payload;
    },
    setTriggerGroups(state) {
      state.triggerGroups = {};
    },
    setIsFetchingGroups(state, action: PayloadAction<boolean>) {
      state.isFetchingGroups = action.payload;
    },
    resetServiceDetail: () => initialState,
  },
});

export const {
  setService,
  setIsFetchingService,
  setIsLoadingError,
  resetServiceDetail,
  setServiceUsers,
  setTriggerUsers,
  setUsersTotalCount,
  setIsFetchingUsers,
  setServiceType,
  setIsShowChangeGroupRoleModal,
  setIsServiceAdmin,
  setIsShowChangeUserRoleModal,
  setIsShowAddUserModal,
  setIsShowAddGroupModal,
  setServiceGroups,
  setGroupsTotalCount,
  setTriggerGroups,
  setIsFetchingGroups,
} = serviceDetail.actions;

export default serviceDetail.reducer;
