import { RoutePath } from '../constants/RoutePath';
import { REDIRECT_URL } from '../constants/queries';
import { Token } from '../enum/Token';

export const redirectToUrl = (redirectUrl: string) => {
  const accessToken = localStorage.getItem(Token.ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(Token.REFRESH_TOKEN);

  window.location.href = `${redirectUrl}?access_token=${accessToken}&refresh_token=${refreshToken}`;
};

export const getRedirectUrlPath = (path: RoutePath) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const redirectUrl = urlParams?.get(REDIRECT_URL);
  const redirectUrlPath = redirectUrl ? `?${REDIRECT_URL}=${redirectUrl}` : '';
  return `${path}${redirectUrlPath}`;
};
