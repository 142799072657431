import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUserAccount } from '../../interfaces/IAccount';

export interface IWorkspaceDetailsState {
  data: IUserAccount | null;
  isFetchingWorkspace: boolean;
  isShowEditAccountModal: boolean; // TODO remove
  isLoadingError: boolean;
  logo: string | null;
  isWorkspaceAdmin: boolean;
  isWorkspaceOwner: boolean;
}

export const initialState: IWorkspaceDetailsState = {
  data: null,
  isFetchingWorkspace: false,
  isShowEditAccountModal: false,
  isLoadingError: false,
  logo: null,
  isWorkspaceAdmin: false,
  isWorkspaceOwner: false,
};

export const workspaceDetail = createSlice({
  name: 'workspaceDetail',
  initialState,
  reducers: {
    setWorkspace(state, action: PayloadAction<IUserAccount | null>) {
      state.data = action.payload;
    },
    setIsFetchingWorkspace(state, action: PayloadAction<boolean>) {
      state.isFetchingWorkspace = action.payload;
    },
    setIsShowEditAccountModal(state, action: PayloadAction<boolean>) {
      state.isShowEditAccountModal = action.payload;
    },
    setIsLoadingError(state, action: PayloadAction<boolean>) {
      state.isLoadingError = action.payload;
    },
    setLogo(state, action: PayloadAction<string | null>) {
      state.logo = action.payload;
    },
    setIsWorkspaceAdmin(state, action: PayloadAction<boolean>) {
      state.isWorkspaceAdmin = action.payload;
    },
    setIsWorkspaceOwner(state, action: PayloadAction<boolean>) {
      state.isWorkspaceOwner = action.payload;
    },
    resetWorkspaceDetail: () => initialState,
  },
});

export const {
  setWorkspace,
  setIsFetchingWorkspace,
  setIsShowEditAccountModal,
  setIsLoadingError,
  resetWorkspaceDetail,
  setLogo,
  setIsWorkspaceAdmin,
  setIsWorkspaceOwner,
} = workspaceDetail.actions;

export default workspaceDetail.reducer;
