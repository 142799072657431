import { Avatar } from '@mui/material';
import {
  FC, useEffect, useState, ReactNode,
} from 'react';

import { toDataURL } from '../../../util/getBase64Image';

interface IComponentProps {
  path: string;
  icon?: ReactNode;
}

const AppAvatar: FC<IComponentProps> = ({
  path, icon,
}) => {
  const [avatar, setAvatar] = useState<string | undefined>();

  useEffect(() => {
    if (path) {
      toDataURL(path, setAvatar);
    } else {
      setAvatar('');
    }
  }, [path]);

  return <Avatar src={avatar}>{icon}</Avatar>;
};

export default AppAvatar;
