import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IServiceType } from '../../interfaces/IServiceType';

export interface IServicesState {
  serviceTypes: IServiceType[];
  isFetchingServiceTypes: boolean;
}

export const initialState: IServicesState = {
  serviceTypes: [],
  isFetchingServiceTypes: false,
};

export const serviceTypes = createSlice({
  name: 'serviceTypes',
  initialState,
  reducers: {
    setServiceTypes(state, action: PayloadAction<IServiceType[]>) {
      state.serviceTypes = action.payload;
    },
    setIsFetchingServiceTypes(state, action: PayloadAction<boolean>) {
      state.isFetchingServiceTypes = action.payload;
    },
  },
});

export const {
  setServiceTypes,
  setIsFetchingServiceTypes,
} = serviceTypes.actions;

export default serviceTypes.reducer;
