import { RootStateType } from '../store';

export const userSelector = (state: RootStateType) => {
  return state.userReducer.user;
};

export const isFetchingUserSelector = (state: RootStateType) => {
  return state.userReducer.isFetchingUserData;
};

export const userRolesSelector = (state: RootStateType) => {
  return state.userReducer.roles;
};

export const loggedAccountSelector = (state: RootStateType) => {
  return state.userReducer.loggedAccount;
};

export const isShowResetPasswordModalSelector = (state: RootStateType) => {
  return state.userReducer.isShowResetPasswordModal;
};

export const isShowSetup2FAModalSelector = (state: RootStateType) => {
  return state.userReducer.isShowSetup2FAModal;
};

export const userIdSelector = (state: RootStateType) => {
  return state.userReducer.userId;
};

export const avatarSelector = (state: RootStateType) => {
  return state.userReducer.avatar;
};

export const isSuperAdminSelector = (state: RootStateType) => {
  return state.userReducer.isSuperAdmin;
};

export const setup2faDataSelector = (state: RootStateType) => {
  return state.userReducer.setup2faData;
};

export const isLoading2faSetupSelector = (state: RootStateType) => {
  return state.userReducer.isLoading2faSetup;
};

export const tokensSelector = (state: RootStateType) => {
  return state.userReducer.tokens;
};
