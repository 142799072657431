import { RoutePath } from '../constants/RoutePath';

export const VISITED_PAGE = 'visited_page';

const IGNORE = [
  RoutePath.HOME_PAGE,
  RoutePath.LOGIN,
  RoutePath.SIGN_UP,
  RoutePath.NOT_FOUND_PAGE,
  RoutePath.NOT_VERIFIED,
  RoutePath.VERIFY_EMAIL,
  RoutePath.VERIFY_CODE,
];

export const saveVisitedPage = (pathName: string, userId: string) => {
  const itemKey = `${VISITED_PAGE}_${userId}`;
  const visitedPage = sessionStorage.getItem(itemKey);

  if (
    pathName
    && !IGNORE.includes(pathName as RoutePath)
    && visitedPage !== pathName
  ) {
    sessionStorage.setItem(itemKey, pathName);
  }
};

export const removeVisitedPage = (userId: string) => {
  const itemKey = `${VISITED_PAGE}_${userId}`;
  const visitedPage = sessionStorage.getItem(itemKey);

  if (visitedPage) {
    sessionStorage.removeItem(itemKey);
  }
};
