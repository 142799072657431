import { ThunkActionType } from '../store';
import { resetAppHeaderState, setApiError, setIsAppLoader } from './appReducer';
import { arrayBufferToString } from '../../util/arrayBufferToString';
import { HTTPCodes } from '../../enum/HTTPCodes';
import { checkAuthApi } from '../../services/api/authApi';
import { logoutAccount, updateTokens } from '../auth/authThunk';
import { initUserData } from '../user/userThunks';
import { RoutePath } from '../../constants/RoutePath';
import { USER_INFO_ERROR, TEMPORARY_PASSWORD_ERROR, USER_EMAIL_IS_UNVERIFIED } from '../../constants/errorMessages';
import { setIsFirstLogin, setIsFirstLoginData } from '../auth/authReducer';
import { router } from '../../components/layouts/AppRouter/AppRouter';
import { getRedirectUrlPath } from '../../util/redirectToUrl';
import { translate, tryToTranslate } from '../../util/translate';

export const handleApiError = (error: any): ThunkActionType => {
  return (dispatch) => {
    if (error?.response && error.response?.data) {
      const data = error.response.data instanceof ArrayBuffer
        ? JSON.parse(arrayBufferToString(error.response.data)) : error.response.data;

      if (data.error === undefined && error.response.status === HTTPCodes.LargePayload) {
        dispatch(setApiError({ error: translate('errors.requestTooLarge') }));
      } else if (data.message === TEMPORARY_PASSWORD_ERROR) {
        dispatch(setIsAppLoader(false));
        dispatch(setIsFirstLogin(true));
        dispatch(setIsFirstLoginData(true));
        router.navigate(RoutePath.FIRST_LOGIN);
      } else if (data.message === USER_EMAIL_IS_UNVERIFIED) {
        dispatch(setIsAppLoader(false));
        router.navigate(getRedirectUrlPath(RoutePath.NOT_VERIFIED));
      } else if (data.error === undefined && error.response.status === HTTPCodes.Unauthorized) {
        dispatch(updateTokens());
      } else {
        dispatch(setApiError({
          error: tryToTranslate(data.error || data.message),
          errorTarget: data.errorTarget,
        }));
      }
    } else if (error.message === USER_INFO_ERROR) {
      dispatch(logoutAccount());
    } else if (error.message || error) {
      dispatch(setApiError({
        error: tryToTranslate(error.message || error),
      }));
    }
  };
};

export const initApp = (avoidCheck: boolean): ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsAppLoader(true));

      if (!avoidCheck) {
        const isAuth = await checkAuthApi();

        if (isAuth) {
          await dispatch(updateTokens());
          await dispatch(initUserData());
        }
      }
      dispatch(setIsAppLoader(false));
    } catch (err) {
      dispatch(handleApiError(err));
    }
  };
};

export const resetAppHeader = (): ThunkActionType => async (dispatch) => {
  dispatch(resetAppHeaderState());
};
