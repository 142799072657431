import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer } from '@mui/material';

import Links from './Links/Links';
import pagesLinks from './PagesLinks';
import { RoutePath } from '../../../constants/RoutePath';
import { SupportLabel } from '../SupportLabel/SupportLabel';

import { ReactComponent as Logo } from '../../../assets/logo.svg';

import './NavigationPane.less';

const NavigationPane = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Drawer
      className="navigation-pane"
      variant="permanent"
      anchor="left"
      id="navigation-pane"
      data-testid="navigation-pane"
    >
      <div className="nav-wrapper">
        <Logo className="app__logo" onClick={() => navigate(RoutePath.WORKSPACES)} />
        <Links links={pagesLinks()} pathName={pathname} />
      </div>
      <SupportLabel />
    </Drawer>
  );
};

export default NavigationPane;
