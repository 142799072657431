import { FC, JSX } from 'react';
import { Link } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Box,
  ListItemText,
} from '@mui/material';
import classNames from 'classnames';

import './Links.less';

export interface ILink {
  name: string;
  icon: JSX.Element;
  path?: string;
  key: string;
}

interface IComponentProps {
  links: ILink[];
  pathName: string;
}

const Links: FC<IComponentProps> = ({ links, pathName }) => {
  const renderLinks = (links: ILink[]) => (
    links.map((item) => {
      return (
        <Box
          key={item.name}
          data-testid={item.key}
          className={classNames(
            'links__item',
            { selected: item.path && pathName.includes(item.key) },
          )}
        >
          <ListItem
            key={item.key}
            disablePadding
          >
            <ListItemButton
              component={Link}
              to={item.path ?? ''}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText>
                {item.name}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Box>
      );
    })
  );

  return (
    <List className="links">
      {renderLinks(links)}
    </List>
  );
};

export default Links;
