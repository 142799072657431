import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISubscriptionQuantity } from '../../interfaces/ISubscription';

export interface ISubscriptionState {
  subscriptionQuantity: ISubscriptionQuantity | null;
  isFetchingQuantity: boolean;
}

export const initialState: ISubscriptionState = {
  subscriptionQuantity: null,
  isFetchingQuantity: false,
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionQuantity(state, action: PayloadAction<ISubscriptionQuantity | null>) {
      state.subscriptionQuantity = action.payload;
    },
    setIsFetchingQuantity(state, action: PayloadAction<boolean>) {
      state.isFetchingQuantity = action.payload;
    },
  },
});

export const {
  setSubscriptionQuantity,
  setIsFetchingQuantity,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
