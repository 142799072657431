import { USER_INFO_ERROR } from '../../constants/errorMessages';
import { InvitationResponse } from '../../enum/InvitationResponse';
import { Token } from '../../enum/Token';
import { IAccountInvitation } from '../../interfaces/IAccount';
import { IAuthData, ISignUpData } from '../../interfaces/IAuthData';
import apiInstance from './instances/apiInstances';
import authApiInstance from './instances/authApiInstance';

const KEYCLOAK_CLIENT = process.env.REACT_APP_KEYCLOAK_CLIENT || '';

export const loginApi = async (data: IAuthData) => {
  const body = new URLSearchParams();

  body.append('username', data.userName);
  body.append('password', data.password);
  body.append('client_id', KEYCLOAK_CLIENT);
  body.append('grant_type', 'password');

  const res = await authApiInstance.post('token', body);

  return res.data;
};

export const refreshTokensApi = async (refreshToken: string) => {
  const body = new URLSearchParams();

  body.append('client_id', KEYCLOAK_CLIENT);
  body.append('grant_type', 'refresh_token');
  body.append('refresh_token', refreshToken);

  const res = await authApiInstance.post('token', body);

  return res;
};

export const logoutApi = async () => {
  const body = new URLSearchParams();

  const refreshToken = localStorage.getItem(Token.REFRESH_TOKEN);

  body.append('client_id', KEYCLOAK_CLIENT);
  body.append('refresh_token', refreshToken || '');

  const res = await authApiInstance.post('logout', body);

  return res;
};

export const checkAuthApi = async () => {
  try {
    await authApiInstance.get('userinfo');
    return true;
  } catch {
    throw Error(USER_INFO_ERROR);
  }
};

export const resetPasswordApi = async (password: string) => {
  const res = await apiInstance.post('auth/reset_password', { password });

  return res.data;
};

export const validateTokenApi = async (accountId: number) => {
  const res = await apiInstance.post(`auth/validate_token/${accountId}`);

  return res.data;
};

export const signUpApi = async (payload: ISignUpData) => {
  const res = await apiInstance.post('auth/sign_up', payload);

  return res.data;
};

export const verifyEmailApi = async (token: string | null) => {
  const res = await apiInstance.post(`auth/verify_email?token=${token}`);

  return res.data;
};

export const responseToInvitationApi = async (
  accountId: string | number,
  status: InvitationResponse,
) => {
  const res = await apiInstance.post(`auth/response-to-invitation/${accountId}`, {
    status,
  });

  return res.data;
};

export const getUserInvitationApi = async (
  accountId: number | null,
): Promise<IAccountInvitation> => {
  const res = await apiInstance.get(`auth/get-invitations/${accountId}`);

  return res.data;
};

export const requestPasswordResetApi = async (
  email: string,
  redirectUrl: string,
) => {
  const res = await apiInstance.post('auth/request_password_reset', {
    email, redirectUrl,
  });

  return res.data;
};

export const requestPasswordNoAuthApi = async (
  token: string,
  password: string,
) => {
  const res = await apiInstance.post(`auth/reset_password_without_auth?token=${token}`, {
    password,
  });

  return res.data;
};

export const resendVerificationEmailApi = async (redirectUrl: string, token?: string) => {
  const res = await apiInstance.post('auth/request_validation', {
    redirectUrl,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
