import { Suspense, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';

import NavigationPane from '../../shared/NavigationPane/NavigationPane';
import { ConfirmModal } from '../../shared/ConfirmModal/ConfirmModal';
import AppHeader from '../../shared/AppHeader/AppHeader';
import { RoutePath } from '../../../constants/RoutePath';
import { confirmModalSelector, isLoadingSelector, isShowNavPanelSelector } from '../../../store/app/appSelectors';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { AppNotification } from '../../shared/AppNotification/AppNotification';
import { AppLoader } from '../../shared/AppLoader/AppLoader';
import { authSelector } from '../../../store/auth/authSelectors';
import { useTypedDispatch } from '../../../hooks/useTypedDispatch';
import { setEnvironment, setIsShowNavPanel } from '../../../store/app/appReducer';
import { userSelector } from '../../../store/user/userSelectors';
import { saveVisitedPage } from '../../../util/saveVisitedPage';
import { Environment } from '../../../enum/appEnvironment';

const HIDE_HEADER_PAGES = [
  RoutePath.LOGIN,
  RoutePath.SIGN_UP,
  RoutePath.VERIFY_EMAIL,
  RoutePath.NOT_VERIFIED,
  RoutePath.FORGOT_PASSWORD,
  RoutePath.RESET_PASSWORD,
  RoutePath.VERIFY_CODE,
] as string[];
const HIDE_NAV_PANEL_PAGES = [
  RoutePath.LOGIN,
  RoutePath.FIRST_LOGIN,
  RoutePath.SIGN_UP,
  RoutePath.VERIFY_EMAIL,
  RoutePath.NOT_VERIFIED,
  RoutePath.FORGOT_PASSWORD,
  RoutePath.ACCEPT_INVITATION,
  RoutePath.RESET_PASSWORD,
  RoutePath.VERIFY_CODE,
] as string[];
const SEPARATE_PAGES = [
  RoutePath.LOGIN,
  RoutePath.SIGN_UP,
  RoutePath.VERIFY_EMAIL,
  RoutePath.NOT_VERIFIED,
  RoutePath.FORGOT_PASSWORD,
  RoutePath.RESET_PASSWORD,
  RoutePath.VERIFY_CODE,
] as string[];
const COMMON_ENVS = [
  RoutePath.ABOUT,
  RoutePath.LICENSING,
] as string[];

function Layout() {
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();

  const confirmModal = useSelector(confirmModalSelector);
  const user = useSelector(userSelector);
  const isAuth = useSelector(authSelector);
  const isShowNavPanel = useSelector(isShowNavPanelSelector);
  const isLoading = useSelector(isLoadingSelector);

  const isSeparatePage = useMemo(() => (
    SEPARATE_PAGES.findIndex((item) => pathname.includes(item)) !== -1
  ), [pathname]);
  const hideHeader = useMemo(() => {
    return HIDE_HEADER_PAGES.findIndex((item) => pathname.includes(item)) !== -1;
  }, [pathname]);

  useEffect(() => {
    const urlEnv = `/${pathname?.split('/')?.[1]}`;
    let environment: Environment | null = null;
    if (urlEnv === RoutePath.USER) {
      environment = Environment.USER;
    } else if (urlEnv === RoutePath.WORKSPACE) {
      environment = Environment.WORKSPACE;
    } else if (COMMON_ENVS.includes(urlEnv)) {
      environment = Environment.COMMON;
    }
    dispatch(setEnvironment(environment));
    const isHiddenPage = HIDE_NAV_PANEL_PAGES.findIndex((item) => pathname.includes(item)) !== -1;
    dispatch(setIsShowNavPanel(!isHiddenPage));
  }, [pathname]);

  useEffect(() => {
    if (user) {
      saveVisitedPage(pathname, user.id);
    }
  }, [pathname, user]);

  return (
    <>
      {isLoading && <AppLoader />}
      {confirmModal && <ConfirmModal />}
      <ErrorMessage />
      <AppNotification />
      {isAuth && isShowNavPanel && !isLoading && <NavigationPane />}
      <Box className={classNames('app__main', {
        'full-page': !isShowNavPanel,
        'separate-page': isSeparatePage,
      })}
      >
        {isAuth && !hideHeader && <AppHeader />}
        <Box className="content" id="content-id">
          <Suspense fallback={<AppLoader />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </>
  );
}

export default Layout;
