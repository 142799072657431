import {
  createBrowserRouter, RouterProvider,
} from 'react-router-dom';

import { routerConfig } from './router-config';

export const router = createBrowserRouter(routerConfig);

export const AppRouter = () => {
  return (
    <RouterProvider router={router} />
  );
};
