import axios from 'axios';

import { requestInterceptors } from '../interceptors/requestInterceptors';
import { responseInterceptors } from '../interceptors/responseInterceptors';

const authApiInstance = axios.create({
  baseURL: `/auth/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/`,
});

requestInterceptors(authApiInstance);

responseInterceptors(authApiInstance);

export default authApiInstance;
