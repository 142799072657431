// export const UNSUPPORTED_CHARS_ERROR = 'Unsupported chars';
// export const IMAGE_SIZE_ERROR = 'Image must be less than [value]';
// export const KEYCLOAK_UNATHORIZED_ERROR = 'keycloak_unathorized';

export const EMPTY_FIELD_ERROR = 'Field is required';
export const MIN_FIELD_LENGTH_ERROR = 'Field must be at least [value] characters long';
export const MAX_FIELD_LINGTH_ERROR = 'Field should be no more than [value] characters long';
export const SHORT_NAME_CHARS_ERROR = 'Only characters "a-z", "0-9", "-" and "_" are allowed';
export const KEBAB_CASE_ERROR = 'Please use kebab-case. Example: short-name';
export const INVALID_EMAIL_ERROR = 'Email is invalid';
export const USER_INFO_ERROR = 'User info error';
export const THE_CODE_IS_INCORRECT = 'Please enter a valid code';
export const ACCOUNT_DISABLED = 'Account disabled';

export const TEMPORARY_PASSWORD_ERROR = 'TEMPORARY_PASSWORD_SHOULD_BE_RESET';
export const USER_EMAIL_IS_UNVERIFIED = 'EMAIL_IS_NOT_VERIFIED';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const USER_IS_INVITED = 'INVITATION_TO_WORKSPACE_HAVE_NOT_BEEN_ACCEPTED';
export const VERIFICATION_2FA_FAILED = '2FA_VERIFICATION_FAILED';
export const SERVICE_NOT_FOUND = 'PRODUCT_NOT_FOUND';
