import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISubscription } from '../../interfaces/ISubscription';

export interface ISubscriptionsState {
  subscriptions: ISubscription[];
  isFetchingSubscriptions: boolean;
  isFetchingPortal: boolean;
  isShowAddSubscriptionModal: boolean;
  triggerSubscriptions: {};
  totalCount: number;
}

export const initialState: ISubscriptionsState = {
  subscriptions: [],
  isFetchingSubscriptions: false,
  isFetchingPortal: false,
  isShowAddSubscriptionModal: false,
  triggerSubscriptions: {},
  totalCount: 0,
};

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptions(state, action: PayloadAction<ISubscription[]>) {
      state.subscriptions = action.payload;
    },
    setIsFetchingSubscriptions(state, action: PayloadAction<boolean>) {
      state.isFetchingSubscriptions = action.payload;
    },
    setIsFetchingPortal(state, action: PayloadAction<boolean>) {
      state.isFetchingPortal = action.payload;
    },
    setIsShowAddSubscriptionsModal(state, action: PayloadAction<boolean>) {
      state.isShowAddSubscriptionModal = action.payload;
    },
    setTriggerSubscriptions(state) {
      state.triggerSubscriptions = {};
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload;
    },
  },
});

export const {
  setSubscriptions,
  setIsFetchingSubscriptions,
  setIsFetchingPortal,
  setIsShowAddSubscriptionsModal,
  setTriggerSubscriptions,
  setTotalCount,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
