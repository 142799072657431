import i18n from '../services/i18n';

export const translate = (i18nKey: string, ns?: string, value?: {}) => {
  const { t } = i18n;

  return t(i18nKey, {
    ns,
    ...value,
  });
};

export const tryToTranslate = (error: string, filename = 'apiErrors') => {
  const translation = translate(error, filename);

  return translation;
};
