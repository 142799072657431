import { unionBy } from 'lodash';
import { IAccountsRequest } from '../../interfaces/IAccount';
import { getAnyWorkspacesApi } from '../../services/api/accountApi';
import { handleApiError } from '../app/appThunk';
import { ThunkActionType } from '../store';
import {
  setWorkspaces, setIsFetchingWorkspaces, setTotalCount,
  setWorkspacesHeader,
  setTotalCountHeader,
} from './workspacesReducer';

export const getAnyWorkspaces = (
  payload: IAccountsRequest,
): ThunkActionType => async (dispatch) => {
  try {
    dispatch(setIsFetchingWorkspaces(true));
    const { content, totalCount } = await getAnyWorkspacesApi(payload);
    dispatch(setWorkspaces(content));
    dispatch(setTotalCount(totalCount));
  } catch (err) {
    dispatch(handleApiError(err));
  } finally {
    dispatch(setIsFetchingWorkspaces(false));
  }
};

export const getAnyWorkspacesHeader = (
  payload: IAccountsRequest,
): ThunkActionType => async (dispatch, getState) => {
  try {
    dispatch(setIsFetchingWorkspaces(true));
    const { content, totalCount } = await getAnyWorkspacesApi(payload);

    const { workspacesHeader } = getState().workspacesReducer;

    const res = unionBy([
      ...workspacesHeader || [],
      ...content,
    ], 'id');

    dispatch(setWorkspacesHeader(res));
    dispatch(setTotalCountHeader(totalCount));
  } catch (err) {
    dispatch(handleApiError(err));
  } finally {
    dispatch(setIsFetchingWorkspaces(false));
  }
};
