import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IUser } from '../../interfaces/IUser';

export interface IWorkspaceUsersState {
  users: IUser[];
  isFetchingUsers: boolean;
  isShowAddUserModal: boolean;
  isShowChangeRoleModal: boolean;
  totalCount: number;
  triggerUsers: {};
}

export const initialState: IWorkspaceUsersState = {
  users: [],
  isFetchingUsers: false,
  isShowAddUserModal: false,
  isShowChangeRoleModal: false,
  totalCount: 0,
  triggerUsers: {},
};

export const workspaceUsers = createSlice({
  name: 'workspaceUsers',
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<IUser[]>) {
      state.users = action.payload;
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload;
    },
    setIsFetchingUsers(state, action: PayloadAction<boolean>) {
      state.isFetchingUsers = action.payload;
    },
    setIsShowAddUserModal(state, action: PayloadAction<boolean>) {
      state.isShowAddUserModal = action.payload;
    },
    setIsShowChangeRoleModal(state, action: PayloadAction<boolean>) {
      state.isShowChangeRoleModal = action.payload;
    },
    setTriggerUsers(state) {
      state.triggerUsers = {};
    },
  },
});

export const {
  setUsers,
  setIsFetchingUsers,
  setIsShowAddUserModal,
  setIsShowChangeRoleModal,
  setTotalCount,
  setTriggerUsers,
} = workspaceUsers.actions;

export default workspaceUsers.reducer;
