import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAccountState {
  isAuth: boolean;
  isFirstLogin: boolean;
  isFirstLoginData: boolean;
  isLoading: boolean;
  loginErrorCode: number | null;
  loginError: string | null;
  isShowForm: boolean;
}

export const initialState: IAccountState = {
  isAuth: false,
  isLoading: false,
  isFirstLogin: false,
  isFirstLoginData: false,
  loginErrorCode: null,
  loginError: null,
  isShowForm: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload;
    },
    setIsAuthLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsFirstLogin(state, action: PayloadAction<boolean>) {
      state.isFirstLogin = action.payload;
    },
    setIsFirstLoginData(state, action: PayloadAction<boolean>) {
      state.isFirstLoginData = action.payload;
    },
    setLoginErrorCode(state, action: PayloadAction<number | null>) {
      state.loginErrorCode = action.payload;
    },
    setLoginError(state, action: PayloadAction<string | null>) {
      state.loginError = action.payload;
    },
    setIsShowForm(state, action: PayloadAction<boolean>) {
      state.isShowForm = action.payload;
    },
  },
});

export const {
  setAuth,
  setIsAuthLoading,
  setLoginErrorCode,
  setIsFirstLogin,
  setLoginError,
  setIsShowForm,
  setIsFirstLoginData,
} = authSlice.actions;

export default authSlice.reducer;
