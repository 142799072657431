import { RootStateType } from '../store';

export const workspacesSelector = (state: RootStateType) => {
  return state.workspacesReducer.workspaces;
};

export const workspacesHeaderSelector = (state: RootStateType) => {
  return state.workspacesReducer.workspacesHeader;
};

export const isFetchingWorkspacesSelector = (state: RootStateType) => {
  return state.workspacesReducer.isFetchingWorkspaces;
};

export const triggerWorkspacesSelector = (state: RootStateType) => {
  return state.workspacesReducer.triggerWorkspaces;
};

export const triggerWorkspacesHeaderSelector = (state: RootStateType) => {
  return state.workspacesReducer.triggerWorkspacesHeader;
};

export const totalCountSelector = (state: RootStateType) => {
  return state.workspacesReducer.totalCount;
};

export const totalCountHeaderSelector = (state: RootStateType) => {
  return state.workspacesReducer.totalCountHeader;
};
