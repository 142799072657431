import {
  useState, MouseEvent, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  Typography,
  Popover,
  Tooltip,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';

import Breadcrumb, { IBreadCrumbItem } from './components/Breadcrumb/Breadcrumb';
import AccountMenu from './components/AccountMenu/AccountMenu';
import NavigationMobilePane from '../NavigationPane/NavigationMobilePane';
import BurgerMenu from './components/BurgerMenu/BurgerMenu';
import { appHeaderSelector } from '../../../store/app/appSelectors';
import { RoutePath } from '../../../constants/RoutePath';
import { SupportLabel } from '../SupportLabel/SupportLabel';

import { ReactComponent as Logo } from '../../../assets/logo.svg';

import './AppHeader.less';

export interface IAppHeader {
  title: string;
  menuItems?: IBreadCrumbItem[],
  description?: string;
  hideNavToPrev?: boolean,
}

const AppHeader = () => {
  const navigate = useNavigate();

  const data = useSelector(appHeaderSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openMenu, setOpenMenu] = useState(false);

  const changeMenuActivity = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu, setOpenMenu]);

  return (
    <Box className="header" data-testid="header-testid">
      <Box className="mobile-header">
        <Box className="mobile-header_wrapper">
          <Logo className="app__logo" onClick={() => navigate(RoutePath.WORKSPACES)} />
          <BurgerMenu open={openMenu} onClose={changeMenuActivity} />
        </Box>
        <Box className={`mobile-header_menu ${openMenu ? 'open' : 'close'}`}>
          <NavigationMobilePane />
          <Box className="header__header-buttons account-mobile-menu">
            <Box onClick={() => navigate(RoutePath.ABOUT)}>
              <IconButton aria-label="support" className="header__header-buttons-item">
                <FontAwesomeIcon icon={faCircleQuestion} />
              </IconButton>
              <span>About</span>
            </Box>
            <AccountMenu />
          </Box>
          <Box className="mobile-header_footer">
            <SupportLabel />
          </Box>
        </Box>
      </Box>
      <Box className="header__header">
        {!!data?.menuItems
        && <Breadcrumb items={data.menuItems} hideNavToPrev={data.hideNavToPrev} />}
        <Box className="header__header-buttons">
          <Tooltip title="About">
            <IconButton aria-label="support" className="header__header-buttons-item" onClick={() => navigate(RoutePath.ABOUT)}>
              <FontAwesomeIcon icon={faCircleQuestion} />
            </IconButton>
          </Tooltip>
          <AccountMenu />
        </Box>
      </Box>
      <Box className="header__main">
        <Typography component="h1">
          {data.title}
        </Typography>
        {data.description && (
          <>
            <IconButton onClick={handleClick}>
              <FontAwesomeIcon icon={faCircleInfo} className="info" />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography sx={{ p: 2 }}>{data.description}</Typography>
            </Popover>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AppHeader;
