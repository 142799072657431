import {
  IUser, IUsersResponse, IUsersRequest, IAddUserData,
} from '../../interfaces/IUser';
import apiInstance from './instances/apiInstances';

export const getLoggedUserApi = async (): Promise<IUser> => {
  const res = await apiInstance.get('/auth/me');

  return res.data;
};

export const getLoggedUserWithTokenApi = async (
  access_token: string,
): Promise<IUser> => {
  const res = await apiInstance.get('/auth/me', {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  return res.data;
};

export const getAnyUsersApi = async (payload: IUsersRequest): Promise<IUsersResponse> => {
  const res = await apiInstance.get('superUser/user', {
    params: { ...payload },
  });

  return res.data;
};

export const getAnyWorkspaceUsersApi = async (
  accountId: number,
  payload: IUsersRequest,
): Promise<IUsersResponse> => {
  const res = await apiInstance.get(`superUser/account/${accountId}/user`, {
    params: { ...payload },
  });

  return res.data;
};

export const resendAnyUserInvitationApi = async (
  accountId: string | number,
  userId: string,
  redirectUrl: string,
) => {
  const res = await apiInstance.post(`superUser/account/${accountId}/user/resend-user-invitation/${userId}`, {
    redirectUrl,
  });

  return res.data;
};

export const getWorkspaceUsersApi = async (
  accountId: number,
  payload: IUsersRequest,
): Promise<IUsersResponse> => {
  const res = await apiInstance.get(`inAccount/${accountId}/account/user`, {
    params: { ...payload },
  });

  return res.data;
};

export const addAnyWorkspaceUserApi = async (
  accountId: number,
  data: IAddUserData,
): Promise<IUser> => {
  const res = await apiInstance.post(`superUser/account/${accountId}/user`, {
    ...data,
  });

  return res.data;
};

export const addWorkspaceUserApi = async (
  accountId: number,
  data: IAddUserData,
): Promise<IUser> => {
  const res = await apiInstance.post(`inAccount/${accountId}/account/user`, {
    ...data,
  });

  return res.data;
};

export const updateUserDataApi = async (data: FormData, reset: boolean)
: Promise<IUser> => {
  const res = await apiInstance.put(`auth/me?resetAvatar=${reset}`, data);

  return res.data;
};

export const transferOwnershipApi = async (accountId: string | number, userId: string) => {
  const res = await apiInstance.put(`inAccount/${accountId}/account/user/transfer-ownership/${userId}`);

  return res.data;
};

export const resendUserInvitationApi = async (
  accountId: string | number,
  userId: string,
  redirectUrl: string,
) => {
  const res = await apiInstance.post(`inAccount/${accountId}/account/resend-user-invitation/${userId}`, {
    redirectUrl,
  });

  return res.data;
};

export const removeAnyWorkspaceUserApi = async (
  accountId: string | number,
  ids: string[],
) => {
  const res = await apiInstance.delete(`superUser/account/${accountId}/user`, { data: { ids } });

  return res.data;
};

export const removeWorkspaceUserApi = async (
  accountId: string | number,
  ids: string[],
) => {
  const res = await apiInstance.delete(`inAccount/${accountId}/account/user`, { data: { ids } });

  return res.data;
};

export const enable2fApi = async () => {
  const res = await apiInstance.post('auth/setup_2fa');
  return res.data;
};

export const disable2faApi = async () => {
  const res = await apiInstance.post('auth/disable_2fa');
  return res.data;
};

export const verify2faCodeApi = async (password: string, token?: string) => {
  const res = await apiInstance.post('auth/verify_otp', {
    password,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const deleteLoggedUserApi = async () => {
  const res = await apiInstance.delete('auth/me');
  return res.data;
};
