import jwt_decode from 'jwt-decode';

import { Token } from '../enum/Token';
import { UserRole } from '../enum/UserRole';
import { isUpperCase } from './isUpperCase';

interface IToken {
  realm_access: {
    roles: [string]
  }
}

export const getRolesFromToken = (token?: string) => {
  const tokenToDecode = token || localStorage.getItem(Token.ACCESS_TOKEN);

  if (tokenToDecode) {
    const { realm_access } = jwt_decode<IToken>(tokenToDecode);
    return realm_access.roles.filter((role) => isUpperCase(role)) as UserRole[];
  }

  return [];
};
