import { Box } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import AppAvatar from '../../shared/AppAvatar/AppAvatar';
import { RoutePath } from '../../../constants/RoutePath';

import './WorkspacesPage.less';

interface IWorkspaceItem {
  name: string,
  logo: string | null,
  shortName: string,
  isAdmin?: boolean,
  onClick: () => void,
  isSuperAdmin: boolean,
}

const WorkspaceMenuItem: FC<IWorkspaceItem> = ({
  name,
  logo,
  shortName,
  isAdmin = false,
  onClick,
  isSuperAdmin,
}) => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`${RoutePath.WORKSPACE}/${shortName}${RoutePath.MANAGE_WORKSPACE}`);
    onClick();
  }, [shortName]);

  const role = useMemo(() => {
    if (isSuperAdmin) {
      return t('roles.superUser');
    }
    return isAdmin ? t('roles.admin') : t('roles.member');
  }, [isAdmin, isSuperAdmin]);

  return (
    <Box className="workspace-item menu" onClick={handleClick}>
      <AppAvatar
        path={logo ?? ''}
        icon={<FontAwesomeIcon icon={faBuilding} />}
      />
      <Box className="main">
        <Box className="name">{name}</Box>
        <Box className="role">{role}</Box>
      </Box>
    </Box>
  );
};

export default WorkspaceMenuItem;
