import { Token } from '../enum/Token';

export const toDataURL = (url: string, callback: any) => {
  const token = localStorage.getItem(Token.ACCESS_TOKEN);
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    if (xhr.status === 200) {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    } else {
      callback('');
    }
  };
  xhr.open('GET', url);
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  xhr.responseType = 'blob';
  xhr.send();
};
