import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IUser } from '../../interfaces/IUser';
import { UserRole } from '../../enum/UserRole';
import { IAccount } from '../../interfaces/IAccount';

interface ITokens {
  accessToken?: string | null;
  refreshToken?: string | null;
}

interface ISetup2faData {
  qrUrl?: string | null;
  secretKey?: string | null;
}

export interface IUserState {
  user: IUser | null;
  userId: string | null; // TODO: remove
  roles: UserRole[] | null; // TODO: remove
  loggedAccount: IAccount | null; // TODO: remove
  isSuperAdmin: boolean;
  isFetchingUserData: boolean;
  isShowResetPasswordModal: boolean;
  isShowSetup2FAModal: boolean;
  avatar: string | null;
  setup2faData: ISetup2faData | null;
  isLoading2faSetup: boolean;
  tokens: ITokens | null,
}

export const initialState: IUserState = {
  user: null,
  userId: null,
  isFetchingUserData: false,
  roles: null,
  loggedAccount: null,
  isShowResetPasswordModal: false,
  isShowSetup2FAModal: false,
  avatar: null,
  isSuperAdmin: false,
  setup2faData: null,
  isLoading2faSetup: false,
  tokens: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser | null>) {
      state.user = action.payload;
    },
    setUserId(state, action: PayloadAction<string | null>) {
      state.userId = action.payload;
    },
    setIsFetchingUserData(state, action: PayloadAction<boolean>) {
      state.isFetchingUserData = action.payload;
    },
    setRoles(state, action: PayloadAction<UserRole[] | null>) {
      state.roles = action.payload;
    },
    setLoggedAccount(state, action: PayloadAction<IAccount | null>) {
      state.loggedAccount = action.payload;
    },
    setAvatar(state, action: PayloadAction<string | null>) {
      state.avatar = action.payload;
    },
    setIsShowResetPasswordModal(state, action: PayloadAction<boolean>) {
      state.isShowResetPasswordModal = action.payload;
    },
    setIsShowSetup2FAModal(state, action: PayloadAction<boolean>) {
      state.isShowSetup2FAModal = action.payload;
    },
    setIsSuperAdmin(state, action: PayloadAction<boolean>) {
      state.isSuperAdmin = action.payload;
    },
    setSetup2faData(state, action: PayloadAction<ISetup2faData | null>) {
      state.setup2faData = action.payload;
    },
    setIsLoading2faSetup(state, action: PayloadAction<boolean>) {
      state.isLoading2faSetup = action.payload;
    },
    setTokens(state, action: PayloadAction<ITokens | null>) {
      state.tokens = action.payload;
    },
  },
});

export const {
  setUser,
  setIsFetchingUserData,
  setRoles,
  setLoggedAccount,
  setIsShowResetPasswordModal,
  setUserId,
  setAvatar,
  setIsSuperAdmin,
  setIsShowSetup2FAModal,
  setSetup2faData,
  setIsLoading2faSetup,
  setTokens,
} = userSlice.actions;

export default userSlice.reducer;
