import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IGroup } from '../../interfaces/IGroup';
import { IUsersResponse } from '../../interfaces/IUser';

export interface IWorkspaceGroupsState {
  groups: IGroup[];
  isFetchingGroups: boolean;
  totalCount: number;
  triggerGroups: {};
  groupData: IGroup | null;
  groupUsers: IUsersResponse | null;
  isFetchingGroupUsers: boolean;
  triggerUsers: {};
  isShowAddUserModal: boolean;
}

export const initialState: IWorkspaceGroupsState = {
  groups: [],
  isFetchingGroups: false,
  totalCount: 0,
  triggerGroups: {},
  groupData: null,
  groupUsers: null,
  isFetchingGroupUsers: false,
  triggerUsers: {},
  isShowAddUserModal: false,
};

export const workspaceGroups = createSlice({
  name: 'workspaceGroups',
  initialState,
  reducers: {
    setGroups(state, action: PayloadAction<IGroup[]>) {
      state.groups = action.payload;
    },
    setGroupData(state, action: PayloadAction<IGroup>) {
      state.groupData = action.payload;
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload;
    },
    setIsFetchingGroups(state, action: PayloadAction<boolean>) {
      state.isFetchingGroups = action.payload;
    },
    setTriggerGroups(state) {
      state.triggerGroups = {};
    },
    setIsFetchingGroupUsers(state, action: PayloadAction<boolean>) {
      state.isFetchingGroupUsers = action.payload;
    },
    setGroupUsers(state, action: PayloadAction<IUsersResponse | null>) {
      state.groupUsers = action.payload;
    },
    setTriggerUsers(state) {
      state.triggerUsers = {};
    },
    setIsShowAddUserModal(state, action: PayloadAction<boolean>) {
      state.isShowAddUserModal = action.payload;
    },
    resetGroups: () => initialState,
  },
});

export const {
  setGroups,
  setTriggerGroups,
  setTotalCount,
  setIsFetchingGroups,
  setIsFetchingGroupUsers,
  setGroupData,
  setGroupUsers,
  setTriggerUsers,
  resetGroups,
  setIsShowAddUserModal,
} = workspaceGroups.actions;

export default workspaceGroups.reducer;
