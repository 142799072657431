import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { AppRouter } from './components/layouts/AppRouter/AppRouter';
import { isAppLoaderSelector } from './store/app/appSelectors';
import { useTypedDispatch } from './hooks/useTypedDispatch';
import { initApp } from './store/app/appThunk';
import { AppLoader } from './components/shared/AppLoader/AppLoader';
import { RoutePath } from './constants/RoutePath';

import './App.less';

const noAuthPages = [
  RoutePath.VERIFY_EMAIL,
  RoutePath.RESET_PASSWORD,
  RoutePath.FORGOT_PASSWORD,
  RoutePath.SIGN_UP,
];

function App() {
  const dispatch = useTypedDispatch();

  const isAppLoader = useSelector(isAppLoaderSelector);

  useEffect(() => {
    const { pathname } = window.location;
    const avoidCheck = noAuthPages.includes(pathname as RoutePath);
    dispatch(initApp(avoidCheck));
  }, []);

  if (isAppLoader) {
    return <AppLoader />;
  }

  return (
    <Box className="app" data-testid="app-component">
      <AppRouter />
    </Box>
  );
}

export default App;
