import {
  useState,
  MouseEvent,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  faAngleDown,
  faAngleUp,
} from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useTypedDispatch } from '../../../../../hooks/useTypedDispatch';
import { logoutAccount } from '../../../../../store/auth/authThunk';
import { RoutePath } from '../../../../../constants/RoutePath';
import { avatarSelector, isSuperAdminSelector, userSelector } from '../../../../../store/user/userSelectors';
import WorkspaceMenuItem from '../../../../layouts/WorkspacesPage/WorkspaceMenuItem';
import { environmentSelector } from '../../../../../store/app/appSelectors';
import { isWorkspaceAdminSelector } from '../../../../../store/workspaceDetail/workspaceDetailSelectors';
import { IAccountsRequest, IUserAccount } from '../../../../../interfaces/IAccount';
import {
  totalCountHeaderSelector, workspacesHeaderSelector, triggerWorkspacesHeaderSelector,
} from '../../../../../store/workspaces/workspacesSelectors';
import { getAnyWorkspacesHeader } from '../../../../../store/workspaces/workspacesThunks';
import { removeVisitedPage } from '../../../../../util/saveVisitedPage';
import { Environment } from '../../../../../enum/appEnvironment';

import './AccountMenu.less';

const AccountMenu = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const user = useSelector(userSelector);
  const avatar = useSelector(avatarSelector);
  const environment = useSelector(environmentSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const superAdminWorkspaces = useSelector(workspacesHeaderSelector);
  const totalCount = useSelector(totalCountHeaderSelector);
  const trigger = useSelector(triggerWorkspacesHeaderSelector);
  const isWorkspaceAdmin = useSelector(isWorkspaceAdminSelector);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [items, setItems] = useState<IUserAccount[] | null>();
  const [page, setPage] = useState(1);
  const listInnerRef = useRef();
  const open = Boolean(anchorEl);

  const userData = useMemo(() => {
    if (user) {
      const name = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim();
      return {
        name,
        email: user.email,
        placeholder: name?.[0] || user?.email?.[0],
      };
    }
    return {};
  }, [user]);

  const userRole = useMemo(() => {
    if (isSuperAdmin) {
      return t('roles.superUser');
    }

    if ([Environment.USER, Environment.COMMON].includes(environment as Environment)) {
      return '';
    }

    if (isWorkspaceAdmin) {
      return t('roles.admin');
    }

    return t('roles.member');
  }, [isSuperAdmin, isWorkspaceAdmin, environment]);

  useEffect(() => {
    setPage(1);
  }, [trigger]);

  useEffect(() => {
    if (isSuperAdmin && open) {
      const payload: IAccountsRequest = {
        pageSize: 5,
        page,
      };
      dispatch(getAnyWorkspacesHeader(payload));
    } else if (open) {
      setItems(user?.accounts || []);
    }
  }, [user, isSuperAdmin, open, page]);

  useEffect(() => {
    if (isSuperAdmin) {
      setItems(superAdminWorkspaces);
    }
  }, [superAdminWorkspaces, isSuperAdmin]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = useCallback(() => {
    dispatch(logoutAccount());
    removeVisitedPage(user?.id ?? '');
    handleClose();
  }, [user]);

  const menu = useMemo(() => [
    {
      label: t('logOut'),
      onClick: handleLogoutClick,
      icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
      dataTestId: 'logout-testid',
    },
  ], [handleLogoutClick]);

  const workspaces = useMemo(() => (
    items?.map(({
      shortName,
      name,
      logo,
      isAdmin,
    }) => (
      <WorkspaceMenuItem
        key={shortName}
        shortName={shortName}
        name={name}
        logo={logo}
        isAdmin={isAdmin}
        onClick={handleClose}
        isSuperAdmin={isSuperAdmin}
      />
    )) || []
  ), [items, isSuperAdmin]);

  const onScroll = useCallback(() => {
    if (listInnerRef.current && isSuperAdmin) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollHeight - (scrollTop + clientHeight) <= 1
        && totalCount > (superAdminWorkspaces?.length ?? 0)
      ) {
        setPage((prev) => prev + 1);
      }
    }
  }, [listInnerRef, totalCount, superAdminWorkspaces, isSuperAdmin]);

  return (
    <Box className="account-menu" data-testid="menu-testid">
      <Box>
        <IconButton
          onClick={handleClick}
          size="small"
          className="account-menu__logo"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar src={avatar || ''}>
            {userData?.placeholder}
          </Avatar>
          <Box className="name-wrapper" data-testid="user-role-testid">
            {userData?.name || userData?.email}
            {userRole && <span className="role">{userRole}</span>}
          </Box>
          <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
        </IconButton>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className="account-menu__menu"
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {userData?.email && (
        <Box
          className="account-menu__menu-header"
        >
          <Box className="account-menu__menu-header-wrapper">
            <Box className="account-menu__menu-header-info">
              <Typography className="name">{userData?.name}</Typography>
              <Typography className="email">{userData?.email}</Typography>
            </Box>
            <Box
              className="account-menu__menu-header-profile"
              component={Link}
              to={RoutePath.PERSONAL_DATA}
              onClick={handleClose}
            >
              {t('myProfile')}
            </Box>
          </Box>
        </Box>
        )}
        <Box
          className="account-menu__menu-workspaces"
        >
          {workspaces.length > 0 && (
          <>
            <Typography className="title">{t('workspaces')}</Typography>
            <Box
              ref={listInnerRef}
              onScroll={onScroll}
              className="account-menu__menu-workspaces-wrapper"
            >
              {workspaces}
            </Box>
          </>
          )}
          <Box
            className="account-menu__menu-workspaces-manage"
            component={Link}
            to={RoutePath.WORKSPACES}
            onClick={handleClose}
          >
            {t('manageWorkspaces')}
          </Box>
        </Box>
        <Box className="account-menu__menu-links">
          {menu.map((item) => (
            <MenuItem key={item.label} onClick={item.onClick} data-testid={item.dataTestId} className="account-menu__menu-links-item">
              {item.icon}
              <Typography className="link">{item.label}</Typography>
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </Box>
  );
};

export default AccountMenu;
