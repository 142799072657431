import { RootStateType } from '../store';

export const isAppLoaderSelector = (state: RootStateType) => {
  return state.appReducer.isAppLoader;
};

export const apiErrorSelector = (state: RootStateType) => {
  return state.appReducer.apiError;
};

export const appNotificationSelector = (state: RootStateType) => {
  return state.appReducer.notification;
};

export const confirmModalSelector = (state: RootStateType) => {
  return state.appReducer.confirmModal;
};

export const appHeaderSelector = (state: RootStateType) => {
  return state.appReducer.appHeader;
};

export const environmentSelector = (state: RootStateType) => {
  return state.appReducer.environment;
};

export const isShowNavPanelSelector = (state: RootStateType) => {
  return state.appReducer.isShowNavPanel;
};

export const isLoadingSelector = (state: RootStateType) => {
  return state.appReducer.isLoading;
};
