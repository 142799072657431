import {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Alert, Snackbar, Typography } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import { SnackbarProps } from '@mui/material/Snackbar/Snackbar';

import { useTypedDispatch } from '../../../hooks/useTypedDispatch';
import { IAppNotification, AppNotificationType } from '../../../interfaces/IAppNotification';
import { setNotification } from '../../../store/app/appReducer';
import { appNotificationSelector } from '../../../store/app/appSelectors';

const TransitionDown = (props: SlideProps) => {
  return <Slide {...props} direction="down" />;
};

export const AppNotification: FC = () => {
  const dispatch = useTypedDispatch();

  const appNotification = useSelector(appNotificationSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [notificationData, setNotificationData] = useState<IAppNotification | null>(null);

  const onClose = () => {
    setIsOpen(false);
  };

  const isNotification = useMemo(
    () => notificationData?.type === AppNotificationType.NOTIFICATION,
    [notificationData],
  );

  const snackbarProps = useMemo((): SnackbarProps => ({
    autoHideDuration: isNotification ? 3000 : 1500,
    anchorOrigin: {
      vertical: 'top',
      horizontal: isNotification ? 'right' : 'center',
    },
  }), [isNotification]);

  useEffect(() => {
    if (appNotification) {
      setNotificationData(appNotification);
      setIsOpen(true);
      dispatch(setNotification(null));
    }
  }, [appNotification]);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setNotificationData(null), 200);
    }
  }, [isOpen]);

  return (
    <Snackbar
      {...snackbarProps}
      open={isOpen}
      data-testid="notification-id"
      TransitionComponent={TransitionDown}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={notificationData?.level}>
        {notificationData?.message
          && (
            <Typography>
              {
                notificationData.message
              }
            </Typography>
          )}
        {notificationData?.description && (
          <Typography variant="caption">
            {
              notificationData.description
            }
          </Typography>
        )}
      </Alert>
    </Snackbar>
  );
};
