import {
  FC, useEffect, useState,
} from 'react';
import {
  Alert,
  Snackbar,
} from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import { useSelector } from 'react-redux';

import { useTypedDispatch } from '../../../hooks/useTypedDispatch';
import { setApiError } from '../../../store/app/appReducer';
import { apiErrorSelector } from '../../../store/app/appSelectors';

const TransitionDown = (props: SlideProps) => {
  return <Slide {...props} direction="down" />;
};

export const ErrorMessage: FC = () => {
  const dispatch = useTypedDispatch();

  const apiError = useSelector(apiErrorSelector);
  const [state, setState] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (apiError && apiError !== null) {
      const { error } = apiError;

      if (error) {
        setState(true);
        setMessage(error as string);

        dispatch(setApiError(null));
      }
    }
  }, [apiError]);

  const onClose = () => setState(false);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={state}
      autoHideDuration={1500}
      onClose={onClose}
      TransitionComponent={TransitionDown}
    >
      <Alert
        onClose={onClose}
        severity="error"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
