export enum AppNotificationType {
  MESSAGE = 'message',
  NOTIFICATION = 'notification',
}

export interface IAppNotification {
  type: AppNotificationType,
  level: 'success' | 'info' | 'warning',
  message: string;
  description?: string;
  messageOptions?: object;
  descriptionOptions?: object;
}
