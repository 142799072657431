import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiError } from '../../interfaces/IApiError';
import { IAppNotification } from '../../interfaces/IAppNotification';
import { IConfirmModal } from '../../util/callConfirmModal';
import { IAppHeader } from '../../components/shared/AppHeader/AppHeader';
import { Environment } from '../../enum/appEnvironment';

export interface IAccountState {
  isAppLoader: boolean;
  apiError: IApiError | null;
  notification: IAppNotification | null;
  confirmModal: IConfirmModal | null;
  appHeader: IAppHeader;
  environment: Environment | null;
  isShowNavPanel: boolean;
  isLoading: boolean;
}

export const initialState: IAccountState = {
  isAppLoader: true,
  apiError: null,
  notification: null,
  confirmModal: null,
  appHeader: {
    title: '',
  },
  environment: null,
  isShowNavPanel: false,
  isLoading: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsAppLoader(state, action: PayloadAction<boolean>) {
      state.isAppLoader = action.payload;
    },
    setApiError(state, action: PayloadAction<IApiError | null>) {
      state.apiError = action.payload;
    },
    setNotification(state, action: PayloadAction<IAppNotification | null>) {
      state.notification = action.payload;
    },
    setConfirmModal(state, action: PayloadAction<IConfirmModal | null>) {
      state.confirmModal = action.payload;
    },
    setEnvironment(state, action: PayloadAction<Environment | null>) {
      state.environment = action.payload;
    },
    setAppHeader(state, action: PayloadAction<IAppHeader>) {
      state.appHeader = action.payload;
    },
    setIsShowNavPanel(state, action: PayloadAction<boolean>) {
      state.isShowNavPanel = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    resetAppHeaderState(state) {
      state.appHeader = initialState.appHeader;
    },
  },
});

export const {
  setIsAppLoader,
  setApiError,
  setNotification,
  setConfirmModal,
  setAppHeader,
  setIsShowNavPanel,
  resetAppHeaderState,
  setIsLoading,
  setEnvironment,
} = appSlice.actions;

export default appSlice.reducer;
