import { RootStateType } from '../store';

export const workspaceDetailSelector = (state: RootStateType) => {
  return state.workspaceDetailReducer.data;
};

export const isFetchingWorkspaceSelector = (state: RootStateType) => {
  return state.workspaceDetailReducer.isFetchingWorkspace;
};

export const isShowEditAccountModalSelector = (state: RootStateType) => {
  return state.workspaceDetailReducer.isShowEditAccountModal;
};

export const isLoadingErrorSelector = (state: RootStateType) => {
  return state.workspaceDetailReducer.isLoadingError;
};

export const logoSelector = (state: RootStateType) => {
  return state.workspaceDetailReducer.logo;
};

export const isWorkspaceAdminSelector = (state: RootStateType) => {
  return state.workspaceDetailReducer.isWorkspaceAdmin;
};

export const isWorkspaceOwnerSelector = (state: RootStateType) => {
  return state.workspaceDetailReducer.isWorkspaceOwner;
};
