import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IUsageInfo } from '../../interfaces/IUsageInfo';

export interface IUsageInfoState {
  data: IUsageInfo[];
  isFetchingUsageInfo: boolean;
}

export const initialState: IUsageInfoState = {
  data: [],
  isFetchingUsageInfo: false,
};

export const usageInfo = createSlice({
  name: 'usageInfo',
  initialState,
  reducers: {
    setUsageInfo(state, action: PayloadAction<IUsageInfo[]>) {
      state.data = action.payload;
    },
    setIsFetchingUsageInfo(state, action: PayloadAction<boolean>) {
      state.isFetchingUsageInfo = action.payload;
    },
    resetUsageInfoData: () => initialState,
  },
});

export const {
  setUsageInfo,
  setIsFetchingUsageInfo,
  resetUsageInfoData,
} = usageInfo.actions;

export default usageInfo.reducer;
