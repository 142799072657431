import jwt_decode from 'jwt-decode';

import { Token } from '../enum/Token';
import { UserRole } from '../enum/UserRole';
import { isUpperCase } from './isUpperCase';

interface IToken {
  preferred_username: string,
  email: string,
  realm_access: {
    roles: [string]
  }
  sub: string,
}

export const getUserFromToken = (token?: string) => {
  const tokenToDecode = token || localStorage.getItem(Token.ACCESS_TOKEN);

  if (tokenToDecode) {
    const {
      realm_access,
      preferred_username,
      sub,
      email,
    } = jwt_decode<IToken>(tokenToDecode);
    return {
      roles: realm_access?.roles?.filter((role: string) => isUpperCase(role)) as UserRole[],
      name: preferred_username,
      id: sub,
      email,
    };
  }

  return {};
};
