import { FC } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RoutePath } from '../../../constants/RoutePath';

import './ErrorPage.less';

interface IComponentProps {
  message: string,
  title: string,
}

const ErrorPage: FC<IComponentProps> = ({ title, message }) => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  return (
    <div className="error-page">
      <Card className="error-page__card">
        <CardContent>
          <Typography variant="h3" component="h1">
            {title}
          </Typography>
          <Typography
            paragraph
            sx={{ mt: 3 }}
            className="secondary"
          >
            {message}
          </Typography>
          <Box
            sx={{ mt: 4 }}
            className="link"
            onClick={() => navigate(RoutePath.WORKSPACES)}
          >
            {t('returnToHome')}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default ErrorPage;
