import ReactDOM from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';

import { store } from './store/store';
import App from './App';
import ProviderWrapper from './components/shared/ProviderWrapper/ProviderWrapper';
import './services/i18n';

import './index.less';

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_PRO_LICENSE_KEY || '');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

export const RenderApp = (
  <ProviderWrapper store={store}>
    <App />
  </ProviderWrapper>
);

root.render(RenderApp);
