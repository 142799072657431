import { JSX } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { UserRole } from '../../../enum/UserRole';
import { userSelector } from '../../../store/user/userSelectors';
import { authSelector } from '../../../store/auth/authSelectors';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import { RoutePath } from '../../../constants/RoutePath';

const PrivateRoute = ({
  children,
  roles,
}: {
  children?: JSX.Element;
  roles?: UserRole[];
}) => {
  const location = useLocation();

  const user = useSelector(userSelector);
  const isAuth = useSelector(authSelector);

  if (!isAuth) {
    return <Navigate to={RoutePath.LOGIN} state={{ from: location }} />;
  }

  const userHasRequiredRole = !!(user && roles?.includes(user.role));

  if (isAuth && roles && user && !userHasRequiredRole) {
    return <NotFoundPage />;
  }

  return children || <Outlet />;
};

export default PrivateRoute;
