import { useMemo } from 'react';
import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

type SupportedLocales = keyof typeof locales;

const useMuiTheme = () => {
  const { i18n } = useTranslation();

  const theme = useMemo(() => {
    const locale = i18n.language?.replace('-', '');

    return createTheme({
      typography: {
        fontFamily: 'Neue Haas Grotesk',
        fontSize: 14.875, // (14 / 16) * expectedFontSizeInPx
        htmlFontSize: 17,
        button: {
          textTransform: 'none',
        },
      },
      components: {
        MuiListItemText: {
          styleOverrides: {
            root: {
              margin: 0,
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: '30px',
            },
          },
        },
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
        },
      },
    }, locales[locale as SupportedLocales]);
  }, [i18n.language]);

  return theme;
};

export default useMuiTheme;
