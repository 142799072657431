import { useSelector } from 'react-redux';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Button, Dialog, DialogContent, DialogTitle, DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { confirmModalSelector } from '../../../store/app/appSelectors';

import './ConfirmModal.less';

export const ConfirmModal = () => {
  const { t } = useTranslation('translation');
  const confirmModal = useSelector(confirmModalSelector);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      data-testid="confirm-modal-id"
      open={!!confirmModal}
      onClose={confirmModal?.onCancel}
      className="confirm-modal"
    >
      <DialogTitle
        display="flex"
        alignItems="center"
      >
        <FontAwesomeIcon icon={faCircleQuestion} className="confirm-modal__icon" />
        {confirmModal?.title ?? ''}
      </DialogTitle>
      <DialogContent>
        {confirmModal?.content}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          data-testid="confirm-cancel-id"
          className="close-btn"
          onClick={confirmModal?.onCancel}
        >
          {confirmModal?.cancelText || t('cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          data-testid="confirm-id"
          onClick={confirmModal?.onAgree}
          className="submit-btn"
        >
          {confirmModal?.okText || t('ok')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
