import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorPage from '../../shared/ErrorPage/ErrorPage';
import { useTypedDispatch } from '../../../hooks/useTypedDispatch';
import { setIsShowNavPanel } from '../../../store/app/appReducer';

const NotFoundPage = () => {
  const { t } = useTranslation('errorPage');
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(setIsShowNavPanel(false));
  }, []);

  return (
    <ErrorPage
      title={t('notFound')}
      message={t('sorry')}
    />
  );
};

export default NotFoundPage;
