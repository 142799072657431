import { AxiosInstance } from 'axios';

import { Token } from '../../../enum/Token';

export function requestInterceptors(instanceAxios: AxiosInstance) {
  return instanceAxios.interceptors.request.use((config: any) => {
    const access_token = localStorage.getItem(Token.ACCESS_TOKEN);
    const { headers = {} } = config;

    if (access_token) {
      headers.Authorization = `Bearer ${access_token}`;
    }

    return {
      ...config,
      headers,
    };
  });
}
